<template>
    <div class="card w-100 border-0 shadow-none" style="height:auto!important;">
        <div class="card-body text-center">
            <div v-if="step === 'login'">
                <div class="font-weight-bold text-grey mb-2">
                    {{ $t('ACCEDI')}}
                </div>
                <div class="d-flex mt-2">
                    <input class="form-control has-label" name="email" :placeholder="$t('E-mail')" autocomplete="off" v-model="login_email" />
                </div>
                <div class="d-flex mt-2">
                    <input v-input-password class="form-control has-label" type="password" :placeholder="$t('Password')" autocomplete="off" v-model="login_password" />
                </div>
                <button class="btn btn-post mt-2 btn-brand w-100 font-size-lg" @click.prevent="login">{{ $t('Accedi') }}</button>

                <div class="mt-2 font-size-sm font-weight-medium">
                    <a href="#" @click.prevent="$emit('update:step', 'register')" class="text-primary">
                        <u>{{ $t('Hai dimenticato la password?') }}</u>
                    </a>
                </div>
                <div class="mt-2 font-size-sm text-grey d-flex align-items-center justify-content-center">
                    <div class="divider mr-2"></div>
                    {{ $t('oppure') }}
                    <div class="divider ml-2"></div>
                </div>
                <div v-if="!app">
                    <v-facebook-login
                            :app-id="facebookAppId"
                            class="mt-3 w-100 text-center"
                            :token-style="{'margin': 0}"
                            :login-options="{scope: 'email,user_gender,user_birthday'}"
                            @login="facebookLogin"
                    >
                        <template slot="login">
                            {{ $t('Accedi con Facebook') }}
                        </template>
                    </v-facebook-login>
                </div>
                <a href="#" class="btn btn-facebook w-100 mt-2" v-if="app" @click.prevent="loginWithNativeFacebook"><i class="fa fa-facebook mr-2"></i> Accedi con facebook</a>
                <div class="mt-2 font-size-sm font-weight-medium">
                    Non hai un account?
                    <a href="#" @click.prevent="$emit('update:step', 'register')" class="text-primary">
                        <u>{{ $t('Registrati') }}</u>
                    </a>
                </div>
            </div>
            <div v-if="step === 'register'">
                <div class="font-weight-bold text-grey mb-2">
                    <span v-if="groupRequest">
                        {{ $t('ISCRIVITI AL GRUPPO')}}
                    </span>
                    <span v-else>
                        {{ $t('REGISTRATI')}}
                    </span>
                </div>

                <div v-if="!app">
                    <v-facebook-login
                            :app-id="facebookAppId"
                            class="mt-3 w-100 text-center"
                            :token-style="{'margin': 0}"
                            :login-options="{scope: 'email,user_gender,user_birthday'}"
                            @login="facebookLogin"
                    >
                        <template slot="login">
                            {{ $t('Accedi con Facebook') }}
                        </template>
                    </v-facebook-login>
                </div>
                <a href="#" class="btn btn-facebook w-100 mt-2" v-if="app" @click.prevent="loginWithNativeFacebook"><i class="fa fa-facebook mr-2"></i> Accedi con facebook</a>

                <div class="mt-2 mb-2 font-size-sm text-grey d-flex align-items-center justify-content-center">
                    <div class="divider mr-2"></div>
                    {{ $t('oppure') }}
                    <div class="divider ml-2"></div>
                </div>
                <div class="d-flex flex-column">
                    <input class="form-control form-control has-label" @focus="clearErrorList" name="first_name" :placeholder="$t('Nome')" autocomplete="off" v-model="first_name" />
                    <div class="form-error text-danger" v-if="errorList.first_name">{{ errorList.first_name[0] }}</div>
                </div>
                <div class="d-flex mt-2 flex-column">
                    <input class="form-control form-control has-label" @focus="clearErrorList" name="last_name" :placeholder="$t('Cognome')" autocomplete="off" v-model="last_name" />
                    <div class="form-error text-danger" v-if="errorList.last_name">{{ errorList.last_name[0] }}</div>
                </div>
                <div class="d-flex mt-2 flex-column">
                    <input class="form-control has-label" name="email" @focus="clearErrorList" :placeholder="$t('E-mail')" autocomplete="off" v-model="email" />
                    <strong class="form-error text-danger mt-2" v-if="errorList.email" v-html="errorList.email[0]"></strong>
                </div>
                <div class="d-flex mt-2 flex-column">
                    <input v-input-password class="form-control has-label" name="password" @focus="clearErrorList" type="password" :placeholder="$t('Password')" autocomplete="off" v-model="password" />
                    <strong class="form-error text-danger mt-1" v-if="errorList.password">{{ errorList.password[0] }}</strong>
                </div>
                <button class="btn btn-post mt-2 btn-brand w-100 font-size-lg" @click.prevent="validateData">{{ $t('Registrati') }}</button>
                <div class="mt-2">
                    <a href="#" class="font-size-sm" @click.prevent="$emit('update:step', 'login')"><u>{{ $t('Ho già un account') }}</u></a>
                </div>
            </div>
            <div v-if="step === 'terms'">
                <div class="font-size-extra font-weight-xbold text-dark-grey">{{ $t('Benvenuto, ') }} {{ first_name }}</div>
                <div class="terms align-justify text-dark-grey py-3 px-lg-3">
                    {{ $t('Toccando accetto, confermi di accettare i ')}}
                    <a href="https://www.iubenda.com/termini-e-condizioni/62789847" class="text-primary" target="_blank"><u>{{ $t('Termini e condizioni')}}</u></a>
                    {{ $t(' di Confestetica, l\'')}}
                    <a href="https://www.confestetica.it/chi-siamo/atto-costitutivo" class="text-primary"><u>{{ $t('Atto costitutivo')}}</u></a>
                    {{ $t(' e ') }}
                    <a href="https://www.confestetica.it/chi-siamo/statuto" class="text-primary"><u>{{ $t('Statuto')}}</u></a>
                    {{ $t(' e la rappresentanza di Confestetica. Scopri in che modo raccogliamo, usiamo e condividiamo i tuoi dati nella nostra ')}}
                    <a href="https://www.iubenda.com/privacy-policy/62789847" class="text-primary" target="_blank"><u>{{ $t('Normativa sui dati')}}</u></a>
                    {{ $t(' e in che modo usiamo i cookie e tecnologie simili nella nostra ') }}
                    <a href="https://www.iubenda.com/privacy-policy/62789847/cookie-policy" class="text-primary" target="_blank"><u>{{ $t('Normativa sui cookie')}}</u></a>.
                    {{ $t(' Potresti anche ricevere notifiche da noi, ma puoi disattivarle in qualsiasi momento.') }}
                </div>

                <div class="d-flex">
                    <button class="btn mt-2 btn-grey w-100 mr-1 flex-basis-0 flex-grow-1" @click.prevent="$emit('update:step', 'register')">{{ $t('Annulla') }}</button>
                    <button class="btn btn-post mt-2 btn-brand w-100 ml-1 flex-basis-0 flex-grow-2" @click.prevent="register()">{{ $t('Accetto') }}</button>
                </div>
            </div>
            <div v-if="step === 'email-confirmation'">
                <strong class="font-size-lg mb-2">{{ $t('Conferma e-mail')}}</strong>
                <div>{{ $t("Dimostraci che questa e-mail ti appartiene, inserisci il codice presente nel messaggio che abbiamo inviato all'indirizzo:") }}</div>
                <div><a href="#" v-if="$auth.loggedIn"><b>{{ $auth.user.email }}</b></a></div>
                <div class="mt-3">
                    <input type="text" :placeholder="$t('Inserisci codice di conferma')" class="form-control" v-model="code" />
                    <button class="btn btn-post mt-2 btn-brand btn-transparent text-black w-100" @click.prevent="confirmEmail">{{ $t('Invia') }}</button>
                </div>
                <hr>
                <button class="btn mt-2 background-white text-black w-100 ml-1 border" @click.prevent="sendConfirmationEmail" v-if="!user.sendingConfirmationEmail"><u>{{ $t('Invia di nuovo l\'e-mail') }}</u></button>
                <button class="btn mt-2 background-white text-black w-100 ml-1 border disabled" v-else><i class="fa fa-spinner fa-spin mr-2"></i>{{ $t('Invio in corso') }}</button>
                <button class="btn mt-2 background-white text-black w-100 ml-1 border" @click.prevent="updateEmail"><u>{{ $t('Cambia indirizzo e-mail') }}</u></button>
            </div>
        </div>
    </div>
</template>

<script>

    import { mapState, mapMutations, mapActions } from 'vuex'
    import { mapFields } from 'vuex-map-fields'
    
    export default {

        props: {
            step: {
                type: String,
                default: function () {
                    return 'register'
                    }
                },
            modal: {
                type: Boolean,
                default: function () {
                    return false
                }
            },
            groupRequest: {
                type: Boolean,
                default: function () {
                    return false
                }
            },
            loginCallback: {
                type: Function,
                default: function () {
                    return null
                }
            },
            subscriptionRequired: {
                type: Boolean,
                default: function () {
                    return false
                }
            }
        },

        data: function() {
            return {
                login_email: '',
                login_password: '',
                errorList: [],
                code: '',
                facebookAppId: process.env.FACEBOOK_CLIENT_ID
            }
        },

        computed: {
            ...mapFields('register', [
                'data.first_name',
                'data.last_name',
                'data.email',
                'data.password',
                'data.password_confirmation',
                'processing'
            ]),
            ...mapState({
                'app': 'app',
                'user': 'user',
                'group': 'group',
                'registration': 'register'
            }),
        },

        methods: {
            ...mapMutations({
                'setEmail': 'user/SET_EMAIL',
                'setUser': 'user/SET_USER'
            }),
            ...mapActions({
                'processRegistration': 'register/processRegistration',
                'processLogin': 'user/login',
                'refetch': 'group/refetch',
                'confirmUserEmail': 'user/confirmEmail',
                'sendUserConfirmationEmail': 'user/sendConfirmationEmail'
            }),
            facebookLogin(payload) {

                let token = payload && payload.authResponse ? payload.authResponse.accessToken : null

                if(token) {
                    this.$nuxt.$loading.start()
                    this.$axios.post('/api/login-social', {
                        social: 'facebook',
                        token: token,
                        referrer_code: this.registration.data.referrer_code,
                        registered_from: this.registration.data.registered_from,
                    }).then((response) => {
                        this.$auth.setUserToken(response.data.access_token)
                            .then(() => {
                                return this.$auth.fetchUser().then(() => {
                                    if(this.groupRequest) {
                                        return this.refetch()
                                    }
                                })
                            })
                    })
                    .finally(() => {
                        this.$nuxt.$loading.finish()
                    })
                }
            },
            loginWithNativeFacebook() {

                let event = { event: 'native-facebook-login' }

                if(window.ReactNativeWebView) {
                    window.ReactNativeWebView.postMessage(JSON.stringify(event));
                } else {
                    window.postMessage(JSON.stringify(event), '*');
                }
            },
            updateEmail () {
                this.$swal.fire({
                    title: 'Inserisci una nuova email',
                    input: 'email',
                    inputValue: this.user.user.email,
                    inputAttributes: {
                        autocapitalize: 'off'
                    },
                    animation: false,
                    // closeButtonHtml: '<i class="cf-back position-fixed p-3 text-black font-size-base" style="left: 0; top: ' + (this.insets ? this.insets.top : 0) + 'px"></i>',
                    customClass: {
                        container: !this.modal ? 'p-0' : '',
                        popup: 'justify-content-start rounded-0' + (!this.modal ? 'pt-5' : ''),
                        confirmButton: 'btn btn-brand btn-sm mx-0 w-100',
                        input: 'form-control mb-0',
                        title: 'font-size-lg mt-5'
                    },
                    position: 'top',
                    grow: !this.modal ? 'fullscreen' : '',
                    showCloseButton: true,
                    confirmButtonText: this.$t('Invia'),
                    confirmButtonColor: '#9B187E',
                    preConfirm: (email) => {

                        this.$nuxt.$loading.start()

                        return this.sendUserConfirmationEmail({ email: email})
                            .then(() => {

                                this.setEmail(email);

                                this.$notify({
                                    group: 'messages',
                                    type: 'success',
                                    title: this.$t('E-mail inviata correttamente. Controlla la tua posta in arrivo.')
                                });
                            })
                            .finally(() => {
                                this.$nuxt.$loading.finish()
                            })
                    },
                    allowOutsideClick: () => !this.$swal.isLoading()
                })
            },
            sendConfirmationEmail() {
                this.sendUserConfirmationEmail()
                    .then(() => {
                        this.$notify({
                            group: 'messages',
                            type: 'success',
                            title: this.$t('E-mail inviata correttamente. Controlla la tua posta in arrivo.')
                        });
                    })
            },

            confirmEmail() {

                this.$nuxt.$loading.start();

                this.confirmUserEmail(this.code)
                    .then(() => {

                        this.$nuxt.$loading.finish();

                        this.$notify({
                            group: 'messages',
                            type: 'success',
                            title: this.$t('E-mail confermata correttamente. Procedi ora con il completamento del tuo profilo.')
                        });

                        this.loginCallback();

                    }).catch(() => {
                        this.$nuxt.$loading.finish();
                    })
            },
            clearErrorList() {
              this.errorList = [];
            },
            validateData() {

                this.$axios.post('/api/validate-data', this.registration.data).then(() => {

                    this.$emit('update:step', 'terms')
                }).catch((error) => {

                    this.errorList = error.response.data.errors;
                })
            },
            login() {

                this.$nuxt.$loading.start()

                this.processLogin({
                        username: this.login_email,
                        password: this.login_password
                    })
                    .then(() => {

                        this.setUser(this.$auth.user)

                        if (!this.$auth.user.confirmed_email) {
                            this.$emit('update:step', 'email-confirmation')
                            if(!this.$auth.user.email) {
                                this.updateEmail()
                            }
                        } else if (this.groupRequest) {
                            this.refetch()
                                .finally(() => {
                                    this.$nuxt.$loading.finish()
                                })
                        } else {
                            this.$nuxt.$loading.finish()
                            this.loginCallback();
                        }
                    })
                    .catch((response) => {
                        this.$notify({
                            group: 'messages',
                            type: 'error',
                            title: 'Le credenziali inserite non risultano essere corrette. Si prega di riprovare.'
                        });

                        this.processing = false;
                    }).finally(() => {
                        this.$nuxt.$loading.finish()
                    })
            },
            register() {

                let _self = this;

                this.$nuxt.$loading.start()

                this.processRegistration(this.groupRequest ? this.group.group.id : null)
                    .then(() => {

                        return this.processLogin({
                            username: this.email,
                            password: this.password
                        })
                        .then(() => {

                            this.setUser(this.$auth.user)

                            if (this.groupRequest) {
                                return this.refetch().then(() => {

                                    this.$emit('update:step', 'email-confirmation')

                                    _self.$notify({
                                        group: 'messages',
                                        type: 'success',
                                        duration: 3000,
                                        title: 'Registrazione effettuata correttamente'
                                    });
                                })
                            } else {
                                this.$emit('update:step', 'email-confirmation')

                                _self.$notify({
                                    group: 'messages',
                                    type: 'success',
                                    duration: 3000,
                                    title: 'Registrazione effettuata correttamente'
                                });
                            }
                        })
                    })
                    // .catch(() => {
                    //     _self.$notify({
                    //         group: 'messages',
                    //         type: 'error',
                    //         duration: 3000,
                    //         title: 'Problema durante la registrazione'
                    //     });
                    // })
                    .finally(() => {
                        this.$nuxt.$loading.finish()
                    })
            },
        },
        created () {
            if (this.$auth.loggedIn && !this.$auth.user.confirmed_email) {
                this.$emit('update:step', 'email-confirmation')
                if(!this.$auth.user.email) {
                    this.updateEmail()
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
    .terms {
        line-height: 1.3;
    }

    .divider {
        width: 5%;
        margin-top: 3px;
        border-top: 1px solid #8C8B8C;
    }
</style>