
import _ from 'lodash'
import Vue from "vue";
import {getField, updateField} from 'vuex-map-fields'

export const state = () => ({
    app: false,
    tmpMode: false,
    mobileMode: false,
    bottomOffset: 0,
    keyboardOpened: false,
    orientation: 'portrait',
    menuOpen: false,
    menuOpening: false,
    dropupOpen: false,
    prevRoute: '/',
    platform: 'browser',
    pushToken: null,
    insets: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
    }
})

export const getters = {
    getField,
    account(state) {
        if (!state.auth.user) {
            return null
        }

        return _.first(state.auth.user.accounts);
    },
    platform (state) {
        return state.platform;
    },
    hasACompanyAccount (state) {
        if (!state.auth.user) {
            return false
        }

        return (state.auth.user.beauty_centre && state.auth.user.beauty_centre.pivot.owner);
        // return state.auth.user.beauty_centre;
    },
}

export const mutations = {
    updateField,
    SET_PUSH_TOKEN (state, pushToken) {
        state.pushToken = pushToken
    },
    SET_MOBILE_MODE (state, mobileMode) {
        state.mobileMode = mobileMode
    },
    SET_BOTTOM_OFFSET (state, offset) {
        state.bottomOffset = offset
    },
    SET_ORIENTATION (state, orientation) {
        state.orientation = orientation
    },
    SET_PLATFORM (state, platform) {
        state.platform = platform
    },
    SET_APP_MODE (state, mode) {
        state.app = mode
    },
    SET_DROPUP_OPEN (state, dropupOpen) {
        state.dropupOpen = dropupOpen
    },
    SET_INSETS (state, insets) {
        state.insets = insets
    },
    SET_MENU_OPEN (state, menuOpen) {
        state.menuOpen = menuOpen
    },
    SET_MENU_OPENING (state, opening) {
        state.menuOpening = opening
    },
    SET_KEYBOARD_OPENED (state, keyboardOpened) {
        state.keyboardOpened = keyboardOpened
    },
    SET_PREVIOUS_ROUTE (state, prevRoute) {
        state.prevRoute = prevRoute
    }
}

export const actions = {

    setBottomOffset ({ commit }, offset) {
        commit('SET_BOTTOM_OFFSET', offset)
    },

    setKeyboardOpened ({ commit }, offset) {
        commit('SET_KEYBOARD_OPENED', offset)
    },

    setOrientation ({ commit }, offset) {
        commit('SET_ORIENTATION', offset)
    },

    setAppMode ({ commit }, mode) {
        commit('SET_APP_MODE', mode)
    },

    setMenuOpen ({ commit }, open) {

        commit('SET_MENU_OPENING', false)
        commit('SET_MENU_OPEN', open)
    },

    setDropupOpen ({ commit }, open) {

        commit('SET_DROPUP_OPEN', open)
    },

    setInsets ({ commit }, insets) {
        commit('SET_INSETS', insets)
    },

    authenticateEchoChannels ({ commit, dispatch }, app ) {

        Vue.set(this.$echo.connector.pusher.config.auth.headers, 'Authorization', this.$auth.$storage.getLocalStorage('_token.local'));

        this.$echo.disconnect();
        this.$echo.connect();

        if (!this.$echo.connector.channels['private-App.Group.1']) {
            this.$echo.private('App.Group.1')
                .listen('GroupPostCreated', (event) => {

                    this.$auth.fetchUser()

                    commit('posts/APPEND_POST', event.post)

                    if (this.$auth && event.post && event.post.user && event.post.user.id !== this.$auth.user.id) {
                        app.$notify({
                            group: 'communications',
                            data: {
                                message: event.post.user.first_name + ' ' + event.post.user.last_name + ' ha pubblicato qualcosa sul gruppo',
                                user: event.post.user,
                                url: event.url
                            }
                        });

                        dispatch('notifications/fetch');
                    }
                })
                .listen('GroupPostDeleted', function(data) {

                    commit('posts/REMOVE_POST', data.post)
                });
        }

        if (!this.$echo.connector.channels['private-App.User.' + this.$auth.user.id]) {
            this.$echo.private('App.User.' + this.$auth.user.id)
                .listen('newCommentCreated', (event) => {

                    this.$auth.fetchUser();

                    commit('comments/APPEND_COMMENT', event.comment)

                    app.$notify({
                        group: 'communications',
                        data: {
                            message: event.user.first_name + ' ' + event.user.last_name + ' ha commentato il tuo post',
                            user: event.user,
                            url: event.url
                        }
                    });

                    dispatch('notifications/fetch');
                })
                .listen('commentLiked', (event) => {

                    this.$auth.fetchUser();

                    commit('comments/INCREMENT_LIKES_COUNT', event.comment)

                    app.$notify({
                        group: 'communications',
                        data: {
                            message: 'A ' + event.comment.user.first_name + ' ' + event.comment.user.last_name + ' piace il tuo commento',
                            user: event.comment.user,
                            url: event.url
                        }
                    });

                    dispatch('notifications/fetch');
                })
                .listen('GroupPostLike', (event) => {

                    this.$auth.fetchUser();

                    commit('posts/INCREMENT_LIKES_COUNT', event.post)

                    app.$notify({
                        group: 'communications',
                        data: {
                            message: 'A ' + event.post.user.first_name + ' ' + event.post.user.last_name + ' piace il tuo post',
                            user: event.post.user,
                            url: event.url
                        }
                    });

                    dispatch('notifications/fetch');
                })
                .listen('GroupRequestAccepted', (event) => {

                    this.$auth.fetchUser();

                    dispatch('group/fetchById', 1);

                    app.$notify({
                        group: 'communications',
                        data: {
                            message: event.user.first_name + ' ' + event.user.last_name + ' ha accettato la tua richiesta di accesso al gruppo',
                            user: event.user,
                            url: event.url
                        }
                    });

                    dispatch('notifications/fetch');
                })
                .listen('NewMessage', (event) => {

                    if (this.$router.currentRoute.path === '/chat/' + event.message.conversation_id) {
                        event.message.read_at = new Date();

                        app.$axios.$post('/api/messages/' + event.message.id + '/markAsRead', event.message);

                    } else {
                        app.$notify({
                            group: 'communications',
                            data: {
                                message: event.user.first_name + ' ' + event.user.last_name + ' ti ha scritto un nuovo messaggio',
                                user: event.user,
                                url: event.url
                            }
                        });
                    }

                    commit('messages/APPEND', event.message);
                    dispatch('conversations/fetchById', event.message.conversation_id);
                })
                .listen('MessageRead', (event) => {

                    if (this.$router.currentRoute.path === '/chat/' + event.message.conversation_id) {

                        commit('messages/APPEND', event.message);
                    }
                })
                .listen('SubscriptionStarted', (event) => {

                    this.$auth.fetchUser();

                    // app.$notify({
                    //     group: 'communications',
                    //     data: {
                    //         message: "Congratulazioni, il tuo profilo è stato verificato e pubblicato sull'Albo Confestetica.",
                    //         user: event.user,
                    //         url: event.url
                    //     }
                    // });
                    //
                    // dispatch('notifications/fetch');
                })
                .listen('ProfileVerified', (event) => {

                    this.$auth.fetchUser();

                    app.$notify({
                        group: 'communications',
                        data: {
                            message: "Congratulazioni, il tuo profilo è stato verificato e pubblicato sull'Albo Confestetica.",
                            user: event.user,
                            url: event.url
                        }
                    });

                    dispatch('notifications/fetch');
                })
                .listen('ProfileRejected', (event) => {

                    this.$auth.fetchUser();

                    app.$notify({
                        group: 'communications',
                        data: {
                            message: "Siamo spiacenti, il tuo profilo non ha superato la revisione di Confestetica. Motivazione:" + event.reason ,
                            user: event.user,
                            url: event.url
                        }
                    });

                    dispatch('notifications/fetch');
                })
                .listen('GroupBuyPaymentSucceeded', (event) => {

                    this.$auth.fetchUser();

                    app.$notify({
                        group: 'messages',
                        data: {
                            text: "Il tuo ordine di " + event.product_name + " è stato processato correttamente",
                            user: event.user,
                            url: event.url
                        }
                    });

                    dispatch('notifications/fetch');
                    dispatch('group-buys/fetchBySlug', event.product_slug);
                })
                .listen('GroupBuyPaymentUpdated', (event) => {

                    this.$auth.fetchUser();

                    dispatch('notifications/fetch');
                    dispatch('group-buys/fetchBySlug', event.product_slug);
                })
                .listen('TransactionUpdated', (event) => {

                    this.$auth.fetchUser();
                    app.$nuxt.$emit('refetch-wallet-transactions');
                })
        }

        if (!this.$echo.connector.channels['private-App.Group.1.Admins']) {
            this.$echo.private('App.Group.1.Admins')
                .listen('JoinGroupRequest', (event) => {

                    this.$auth.fetchUser();

                    app.$notify({
                        group: 'communications',
                        data: {
                            message: event.user.first_name + ' ' + event.user.last_name + ' ha fatto richiesta di entrare nel gruppo',
                            user: event.user,
                            url: event.url
                        }
                    });

                    dispatch('notifications/fetch');
                });
        }
    },

    async nuxtServerInit ({ dispatch, commit }, { app, req }) {

        const userAgent = req ? req.headers['user-agent'] : navigator.userAgent;

        commit('SET_MOBILE_MODE', /mobile/i.test(userAgent))

        if (app.$auth.loggedIn) {

            await dispatch('user/fetchById', app.$auth.user.id)
            await dispatch('notifications/fetch')
        }
    },

    nuxtClientInit ({ state, commit, dispatch }, data) {

        commit('SET_MOBILE_MODE', window.innerWidth < 991)

        // let vh = window.innerHeight * 0.01;
        // document.documentElement.style.setProperty('--vh', `${vh}px`);

        window.addEventListener('resize', () => {
            commit('SET_MOBILE_MODE', window.innerWidth < 991)
        });
    },

    openCrashReport () {

        let event = {
            event: 'open-crash-report',
            body: this.app.$auth.loggedIn ? this.app.$auth.getToken('local') : ''
        }

        if (window.ReactNativeWebView) {

            window.ReactNativeWebView.postMessage(JSON.stringify(event));
        } else {

            window.postMessage(JSON.stringify(event), '*');
        }
    }
}