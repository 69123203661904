
import { getField, updateField } from 'vuex-map-fields';
import _ from 'lodash'

export const state = () => ({
    user: {
        city: null,
        postal_code: null
    },
    tab: 'about',
    loading: false,
    saving: false,
    uploadingHeaderImage: false,
    uploadingProfileImage: false,
    uploadingCertificateImage: false,
    confirmingEmail: false,
    resettingPassword: false,
    sendingConfirmationEmail: false
})

export const getters = {
    getField,
    IS_EDITABLE_BY_USER: state => (user) => {

        if(!user) return false;

        return (state.user.id === user.id || user.is_admin);
    }
}

export const mutations = {
    updateField,
    SET_PROFILE_TYPE (state, profileType) {
        state.user.profile_type = profileType
    },
    SET_PROFILE_OWNER (state, owner) {
        state.user.owner = owner
    },
    SET_ACCOUNT_TYPE (state, accountType) {
        state.user.account.type = accountType
    },
    SET_EDUCATION_UPDATE_FREQUENCY (state, value) {
        state.user.education_update_frequency = value;
    },
    SET_USER (state, user) {
        state.user = user
    },
    SET_EMAIL (state, email) {
        state.user.email = email
    },
    SET_USER_TAB (state, tab) {
        state.tab = tab
    },
    SET_LOADING (state, loading) {
        state.loading = loading
    },
    SET_SAVING (state, saving) {
        state.saving = saving
    },
    SET_UPLOADING_HEADER_IMAGE (state, loading) {
        state.uploadingHeaderImage = loading
    },
    SET_UPLOADING_PROFILE_IMAGE (state, loading) {
        state.uploadingProfileImage = loading
    },
    SET_UPLOADING_CERTIFICATE_IMAGE (state, loading) {
        state.uploadingCertificateImage = loading
    },
    SET_CONFIRMING_EMAIL (state, loading) {
        state.confirmingEmail = loading
    },
    SET_SENDING_CONFIRMATION_EMAIL (state, loading) {
        state.sendingConfirmationEmail = loading
    },
    SET_RESETTING_PASSWORD (state, loading) {
        state.resettingPassword = loading
    },
}

export const actions = {

    async refetch ({ state, dispatch }) {
        await dispatch('fetchById', state.user.id)

        if (state.user.id === this.$auth.user.id) {
            await this.$auth.fetchUser()
        }
    },

    completeWizard ({ state, dispatch }) {

        let completedUser = Object.assign({}, state.user);

        completedUser.wizard_completed = true;
        completedUser.wizard_completed_now = true;

        return dispatch('update', completedUser)
    },

    completeQuestionnaire ({ state, dispatch }) {

        let completedUser = Object.assign({}, state.user);

        completedUser.questionnaire_completed = true;

        return dispatch('update', completedUser).then(() => {
            if (state.user.id === this.$auth.user.id) {
                this.$auth.fetchUser()
            }
        })
    },

    login ({ state, app }, loginData) {

        return this.$auth.loginWith('local', {
            data: {

                client_id: process.env.CLIENT_ID,
                client_secret: process.env.CLIENT_SECRET,

                grant_type: 'password',
                username: loginData.username,
                password: loginData.password
            }
        })
        .then((response) => {

            window.customerlySettings.user_id = this.$auth.user.id;
            window.customerlySettings.email = this.$auth.user.email;
            window.customerlySettings.name= this.$auth.user.name;
            customerly('update');

            let events = [
                {
                    event: 'send-push-token',
                },
                {
                    event: 'access-token',
                    body: this.$auth.getToken('local')
                },
            ]

            if(process.client) {
                _.each(events, (event) => {
                    if(window.ReactNativeWebView) {
                        window.ReactNativeWebView.postMessage(JSON.stringify(event));
                    } else {
                        window.postMessage(JSON.stringify(event), '*');
                    }
                })
            }

            return response;
        })
    },

    setStatus ({ state, dispatch }, status) {

        dispatch('update', { status: status })
    },

    setAdminEvaluation ({ state, dispatch }, adminEvaluation) {

        dispatch('update', { admin_evaluation: adminEvaluation })
    },

    setBiography ({ state, dispatch }, biography) {

        dispatch('update', { biography: biography })
    },

    fetchById ({ commit, state, dispatch}, id) {

        commit('SET_LOADING', true)

        return this.$axios
            .get('/api/users/' + id)
            .then(r => r.data)
            .then(user => {
                commit('SET_USER', user)
                commit('users/APPEND_USER', user, { root: true })
                dispatch('certificates/appendCertificates', user.certificates, { root: true })
                commit('SET_LOADING', false)
            })
            .catch(error => {
                commit('SET_LOADING', false)
                throw error;
            })
    },

    update ({ commit, state}, user) {

        commit('SET_SAVING', true)

        return this.$axios
            .put('/api/users/' + state.user.id, user)
            .then(r => r.data)
            .then(user => {

                commit('SET_USER', user)

                if(this.$auth.user && user.id === this.$auth.user.id) {
                    this.$auth.fetchUser()
                }
                commit('SET_SAVING', false)
            })
            .catch(error => {
                commit('SET_SAVING', false)
                throw error;
            })
    },

    updateHeaderImage ({ commit, state }, image) {

        let data = new FormData()

        data.append('header_image', image)

        commit('SET_UPLOADING_HEADER_IMAGE', true)

        return this.$axios
            .post('/api/users/' + state.user.id + '/header-image', data)
            .then(r => r.data)
            .then(user => {
                commit('SET_UPLOADING_HEADER_IMAGE', false)
                commit('SET_USER', user)
            })
            .catch(error => {
                commit('SET_UPLOADING_HEADER_IMAGE', false)
                throw error;
            })
    },

    updateProfileImage ({ commit, state }, image) {

        let data = new FormData()

        data.append('profile_image', image)

        commit('SET_UPLOADING_PROFILE_IMAGE', true)

        return this.$axios
            .post('/api/users/' + state.user.id + '/profile-image', data)
            .then(r => r.data)
            .then(user => {
                commit('SET_UPLOADING_PROFILE_IMAGE', false)
                commit('SET_USER', user)
            })
            .catch(error => {
                commit('SET_UPLOADING_PROFILE_IMAGE', false)
                throw error;
            })
    },

    sendConfirmationEmail ({ commit, state }, data) {

        commit('SET_SENDING_CONFIRMATION_EMAIL', true)

        let email = state.user.email

        if(data && data.email) {
            email = data.email
        }

        return this.$axios
            .post('/api/users/' + state.user.id + '/send-confirmation-email', {email: email})
            .then(r => r.data)
            .then(user => {
                commit('SET_SENDING_CONFIRMATION_EMAIL', false)
                commit('SET_USER', user)
            })
            .catch(error => {
                commit('SET_SENDING_CONFIRMATION_EMAIL', false)
                throw error;
            })
    },

    confirmEmail ({ commit, state }, code) {

        commit('SET_CONFIRMING_EMAIL', true)

        return this.$axios
            .post('/api/users/' + state.user.id + '/confirm-email', {code: code})
            .then(r => r.data)
            .then(user => {

                if(this.$auth.user && user.id === this.$auth.user.id) {
                    this.$auth.fetchUser()
                }

                commit('SET_CONFIRMING_EMAIL', false)
                commit('SET_USER', user)
            })
            .catch(error => {
                commit('SET_CONFIRMING_EMAIL', false)
                throw error;
            })
    },

    sendResetPasswordLink ({ commit, state }, data) {

        commit('SET_RESETTING_PASSWORD', true)

        return this.$axios
            .post('/api/users/send-password-reset', data)
            // .then(r => r.data)
            .then(() => {

                commit('SET_RESETTING_PASSWORD', false)
            })
            .catch(error => {
                commit('SET_RESETTING_PASSWORD', false)
                throw error;
                // return false
            })
    },

    sendPushToken ({ commit }, tokenData) {

        if (this.$auth.loggedIn) {
            commit('SET_PUSH_TOKEN', tokenData, { root: true })

            return this.$axios
                .post('/api/me/push-token', tokenData)
                .then(r => r.data)
                .catch(error => {
                    throw error;
                })
        }
    },

    printBadge ({ state }) {
        return this.$axios
            .get('/api/badge/' + state.user.id)
            .then(r => r.data)
            .catch(error => {
                throw error;
            })
    },

    deletePushToken({ state }, tokenData) {
        return this.$axios.post('api/me/delete-push-token', tokenData)
            .then(r => r.data)
            .catch(error => {
                throw error;
            })
    },

    logout ({ dispatch, rootState }, FacebookSDK) {
        this.$echo.disconnect();
        if (FacebookSDK) {
          FacebookSDK.logout();
        }

        if (rootState.pushToken) {
            dispatch('deletePushToken', rootState.pushToken)
        }

        return this.$auth.logout().then(() => {
            let event = {
                    event: 'access-token',
                    body: ''
                }

            if (process.client) {
                if(window.ReactNativeWebView) {
                    window.ReactNativeWebView.postMessage(JSON.stringify(event));
                } else {
                    window.postMessage(JSON.stringify(event), '*');
                }
            }
        });
    }
}
