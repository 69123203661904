import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _359245b3 = () => interopDefault(import('../pages/abbonamenti/index.vue' /* webpackChunkName: "pages/abbonamenti/index" */))
const _cd81823c = () => interopDefault(import('../pages/abusive/index.vue' /* webpackChunkName: "pages/abusive/index" */))
const _4bea1a16 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _106b93de = () => interopDefault(import('../pages/aggiorna-profilo/index.vue' /* webpackChunkName: "pages/aggiorna-profilo/index" */))
const _73655c0c = () => interopDefault(import('../pages/aziende-cosmetiche/index.vue' /* webpackChunkName: "pages/aziende-cosmetiche/index" */))
const _a58b88e8 = () => interopDefault(import('../pages/badge/index.vue' /* webpackChunkName: "pages/badge/index" */))
const _367fed80 = () => interopDefault(import('../pages/borsellino/index.vue' /* webpackChunkName: "pages/borsellino/index" */))
const _539e3d74 = () => interopDefault(import('../pages/cambia-password/index.vue' /* webpackChunkName: "pages/cambia-password/index" */))
const _c43d33de = () => interopDefault(import('../pages/centri-estetici/index.vue' /* webpackChunkName: "pages/centri-estetici/index" */))
const _275dc6d7 = () => interopDefault(import('../pages/cerca/index.vue' /* webpackChunkName: "pages/cerca/index" */))
const _64d78bb6 = () => interopDefault(import('../pages/chat/index.vue' /* webpackChunkName: "pages/chat/index" */))
const _c16c8cda = () => interopDefault(import('../pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _ca49f692 = () => interopDefault(import('../pages/comunicazioni/index.vue' /* webpackChunkName: "pages/comunicazioni/index" */))
const _22019367 = () => interopDefault(import('../pages/contatti/index.vue' /* webpackChunkName: "pages/contatti/index" */))
const _01325a3d = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _e8fb9dec = () => interopDefault(import('../pages/disattivazione-ed-eliminazione/index.vue' /* webpackChunkName: "pages/disattivazione-ed-eliminazione/index" */))
const _99204520 = () => interopDefault(import('../pages/diventa-socio/index.vue' /* webpackChunkName: "pages/diventa-socio/index" */))
const _8667d00e = () => interopDefault(import('../pages/estetiste/index.vue' /* webpackChunkName: "pages/estetiste/index" */))
const _3b426214 = () => interopDefault(import('../pages/fatture/index.vue' /* webpackChunkName: "pages/fatture/index" */))
const _3a27e578 = () => interopDefault(import('../pages/gestione-pagine/index.vue' /* webpackChunkName: "pages/gestione-pagine/index" */))
const _35bb73b2 = () => interopDefault(import('../pages/gruppi-di-acquisto/index.vue' /* webpackChunkName: "pages/gruppi-di-acquisto/index" */))
const _6de2815c = () => interopDefault(import('../pages/gruppo/index.vue' /* webpackChunkName: "pages/gruppo/index" */))
const _170f97fd = () => interopDefault(import('../pages/impostazioni-di-notifica/index.vue' /* webpackChunkName: "pages/impostazioni-di-notifica/index" */))
const _be37e05c = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _1460e17d = () => interopDefault(import('../pages/luogo-di-lavoro/index.vue' /* webpackChunkName: "pages/luogo-di-lavoro/index" */))
const _c484515a = () => interopDefault(import('../pages/marchi/index.vue' /* webpackChunkName: "pages/marchi/index" */))
const _461c5498 = () => interopDefault(import('../pages/marketplace/index.vue' /* webpackChunkName: "pages/marketplace/index" */))
const _64c1c128 = () => interopDefault(import('../pages/menu/index.vue' /* webpackChunkName: "pages/menu/index" */))
const _3d457eb0 = () => interopDefault(import('../pages/notifiche/index.vue' /* webpackChunkName: "pages/notifiche/index" */))
const _918feb82 = () => interopDefault(import('../pages/pannello-amministrazione/index.vue' /* webpackChunkName: "pages/pannello-amministrazione/index" */))
const _bdcfacac = () => interopDefault(import('../pages/password-reset/index.vue' /* webpackChunkName: "pages/password-reset/index" */))
const _5ce7ea1c = () => interopDefault(import('../pages/profilo/index.vue' /* webpackChunkName: "pages/profilo/index" */))
const _46d9fd36 = () => interopDefault(import('../pages/punto-estetico/index.vue' /* webpackChunkName: "pages/punto-estetico/index" */))
const _6727cc1f = () => interopDefault(import('../pages/ricarica-borsellino/index.vue' /* webpackChunkName: "pages/ricarica-borsellino/index" */))
const _39d15470 = () => interopDefault(import('../pages/scuole/index.vue' /* webpackChunkName: "pages/scuole/index" */))
const _dc78ae44 = () => interopDefault(import('../pages/test-estetista/index.vue' /* webpackChunkName: "pages/test-estetista/index" */))
const _1d596472 = () => interopDefault(import('../pages/aggiorna-profilo/biografia/index.vue' /* webpackChunkName: "pages/aggiorna-profilo/biografia/index" */))
const _0e009ea4 = () => interopDefault(import('../pages/aggiorna-profilo/diploma/index.vue' /* webpackChunkName: "pages/aggiorna-profilo/diploma/index" */))
const _6ee93c71 = () => interopDefault(import('../pages/aggiorna-profilo/immagine-copertina/index.vue' /* webpackChunkName: "pages/aggiorna-profilo/immagine-copertina/index" */))
const _3599f94b = () => interopDefault(import('../pages/aggiorna-profilo/immagine-profilo/index.vue' /* webpackChunkName: "pages/aggiorna-profilo/immagine-profilo/index" */))
const _3c09d5fa = () => interopDefault(import('../pages/aggiorna-profilo/specializzazione/index.vue' /* webpackChunkName: "pages/aggiorna-profilo/specializzazione/index" */))
const _0d8df642 = () => interopDefault(import('../pages/gruppo/nuovo-post/index.vue' /* webpackChunkName: "pages/gruppo/nuovo-post/index" */))
const _104bc18f = () => interopDefault(import('../pages/aggiorna-profilo/certificati/nuovo/index.vue' /* webpackChunkName: "pages/aggiorna-profilo/certificati/nuovo/index" */))
const _96086398 = () => interopDefault(import('../pages/aggiorna-profilo/esperienze-lavorative/nuovo/index.vue' /* webpackChunkName: "pages/aggiorna-profilo/esperienze-lavorative/nuovo/index" */))
const _4125933b = () => interopDefault(import('../pages/pannello-amministrazione/confestetica/gestione-utenti/index.vue' /* webpackChunkName: "pages/pannello-amministrazione/confestetica/gestione-utenti/index" */))
const _65d576b1 = () => interopDefault(import('../pages/pannello-amministrazione/confestetica/richieste-iscrizione/index.vue' /* webpackChunkName: "pages/pannello-amministrazione/confestetica/richieste-iscrizione/index" */))
const _04e025de = () => interopDefault(import('../pages/pannello-amministrazione/confestetica/richieste-iscrizione-aziende/index.vue' /* webpackChunkName: "pages/pannello-amministrazione/confestetica/richieste-iscrizione-aziende/index" */))
const _b5ecd572 = () => interopDefault(import('../pages/pannello-amministrazione/confestetica/segnalazioni/index.vue' /* webpackChunkName: "pages/pannello-amministrazione/confestetica/segnalazioni/index" */))
const _8e2661ac = () => interopDefault(import('../pages/pannello-amministrazione/gruppo/gestione-utenti/index.vue' /* webpackChunkName: "pages/pannello-amministrazione/gruppo/gestione-utenti/index" */))
const _0f9d4fbc = () => interopDefault(import('../pages/pannello-amministrazione/gruppo/richieste-iscrizione/index.vue' /* webpackChunkName: "pages/pannello-amministrazione/gruppo/richieste-iscrizione/index" */))
const _ae2e6510 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _61bb59c5 = () => interopDefault(import('../pages/aggiorna-profilo/certificati/_id.vue' /* webpackChunkName: "pages/aggiorna-profilo/certificati/_id" */))
const _6f60db6a = () => interopDefault(import('../pages/aggiorna-profilo/esperienze-lavorative/_id.vue' /* webpackChunkName: "pages/aggiorna-profilo/esperienze-lavorative/_id" */))
const _1101ecf0 = () => interopDefault(import('../pages/gruppo/modifica-post/_id.vue' /* webpackChunkName: "pages/gruppo/modifica-post/_id" */))
const _7e4cde12 = () => interopDefault(import('../pages/aziende-cosmetiche/_id/index.vue' /* webpackChunkName: "pages/aziende-cosmetiche/_id/index" */))
const _4af1df08 = () => interopDefault(import('../pages/centri-estetici/_id/index.vue' /* webpackChunkName: "pages/centri-estetici/_id/index" */))
const _8676fc66 = () => interopDefault(import('../pages/chat/_id.vue' /* webpackChunkName: "pages/chat/_id" */))
const _3f768e64 = () => interopDefault(import('../pages/estetiste/_id/index.vue' /* webpackChunkName: "pages/estetiste/_id/index" */))
const _0e7f3d26 = () => interopDefault(import('../pages/gruppi-di-acquisto/_slug/index.vue' /* webpackChunkName: "pages/gruppi-di-acquisto/_slug/index" */))
const _6f35de84 = () => interopDefault(import('../pages/marchi/_id/index.vue' /* webpackChunkName: "pages/marchi/_id/index" */))
const _ee8b3db8 = () => interopDefault(import('../pages/posts/_id.vue' /* webpackChunkName: "pages/posts/_id" */))
const _060ab75b = () => interopDefault(import('../pages/scuole/_id/index.vue' /* webpackChunkName: "pages/scuole/_id/index" */))
const _d383886a = () => interopDefault(import('../pages/aziende-cosmetiche/_id/immagine-copertina/index.vue' /* webpackChunkName: "pages/aziende-cosmetiche/_id/immagine-copertina/index" */))
const _9e09f1b6 = () => interopDefault(import('../pages/aziende-cosmetiche/_id/immagine-profilo/index.vue' /* webpackChunkName: "pages/aziende-cosmetiche/_id/immagine-profilo/index" */))
const _5bdebc26 = () => interopDefault(import('../pages/centri-estetici/_id/immagine-copertina/index.vue' /* webpackChunkName: "pages/centri-estetici/_id/immagine-copertina/index" */))
const _05e5e240 = () => interopDefault(import('../pages/centri-estetici/_id/immagine-profilo/index.vue' /* webpackChunkName: "pages/centri-estetici/_id/immagine-profilo/index" */))
const _32b6d718 = () => interopDefault(import('../pages/estetiste/_id/diploma.vue' /* webpackChunkName: "pages/estetiste/_id/diploma" */))
const _7b6c4184 = () => interopDefault(import('../pages/gruppi-di-acquisto/_slug/acquisto-annullato/index.vue' /* webpackChunkName: "pages/gruppi-di-acquisto/_slug/acquisto-annullato/index" */))
const _45d019b0 = () => interopDefault(import('../pages/gruppi-di-acquisto/_slug/acquisto-completato/index.vue' /* webpackChunkName: "pages/gruppi-di-acquisto/_slug/acquisto-completato/index" */))
const _4526b054 = () => interopDefault(import('../pages/gruppi-di-acquisto/_slug/redirect/index.vue' /* webpackChunkName: "pages/gruppi-di-acquisto/_slug/redirect/index" */))
const _260bfeb8 = () => interopDefault(import('../pages/marchi/_id/immagine-copertina/index.vue' /* webpackChunkName: "pages/marchi/_id/immagine-copertina/index" */))
const _7d642e3e = () => interopDefault(import('../pages/marchi/_id/immagine-profilo/index.vue' /* webpackChunkName: "pages/marchi/_id/immagine-profilo/index" */))
const _8b11da32 = () => interopDefault(import('../pages/scuole/_id/immagine-copertina/index.vue' /* webpackChunkName: "pages/scuole/_id/immagine-copertina/index" */))
const _7712157e = () => interopDefault(import('../pages/scuole/_id/immagine-profilo/index.vue' /* webpackChunkName: "pages/scuole/_id/immagine-profilo/index" */))
const _5d2ed6f4 = () => interopDefault(import('../pages/punto-estetico/_/index.vue' /* webpackChunkName: "pages/punto-estetico/_/index" */))
const _2945bfc7 = () => interopDefault(import('../pages/comunicazioni/_/index.vue' /* webpackChunkName: "pages/comunicazioni/_/index" */))
const _f78d9abe = () => interopDefault(import('../pages/centri-estetici/_/index.vue' /* webpackChunkName: "pages/centri-estetici/_/index" */))
const _29f74770 = () => interopDefault(import('../pages/_/index.vue' /* webpackChunkName: "pages/_/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/abbonamenti",
    component: _359245b3,
    name: "abbonamenti"
  }, {
    path: "/abusive",
    component: _cd81823c,
    name: "abusive"
  }, {
    path: "/account",
    component: _4bea1a16,
    name: "account"
  }, {
    path: "/aggiorna-profilo",
    component: _106b93de,
    name: "aggiorna-profilo"
  }, {
    path: "/aziende-cosmetiche",
    component: _73655c0c,
    name: "aziende-cosmetiche"
  }, {
    path: "/badge",
    component: _a58b88e8,
    name: "badge"
  }, {
    path: "/borsellino",
    component: _367fed80,
    name: "borsellino"
  }, {
    path: "/cambia-password",
    component: _539e3d74,
    name: "cambia-password"
  }, {
    path: "/centri-estetici",
    component: _c43d33de,
    name: "centri-estetici"
  }, {
    path: "/cerca",
    component: _275dc6d7,
    name: "cerca"
  }, {
    path: "/chat",
    component: _64d78bb6,
    name: "chat"
  }, {
    path: "/checkout",
    component: _c16c8cda,
    name: "checkout"
  }, {
    path: "/comunicazioni",
    component: _ca49f692,
    name: "comunicazioni"
  }, {
    path: "/contatti",
    component: _22019367,
    name: "contatti"
  }, {
    path: "/dashboard",
    component: _01325a3d,
    name: "dashboard"
  }, {
    path: "/disattivazione-ed-eliminazione",
    component: _e8fb9dec,
    name: "disattivazione-ed-eliminazione"
  }, {
    path: "/diventa-socio",
    component: _99204520,
    name: "diventa-socio"
  }, {
    path: "/estetiste",
    component: _8667d00e,
    name: "estetiste"
  }, {
    path: "/fatture",
    component: _3b426214,
    name: "fatture"
  }, {
    path: "/gestione-pagine",
    component: _3a27e578,
    name: "gestione-pagine"
  }, {
    path: "/gruppi-di-acquisto",
    component: _35bb73b2,
    name: "gruppi-di-acquisto"
  }, {
    path: "/gruppo",
    component: _6de2815c,
    name: "gruppo"
  }, {
    path: "/impostazioni-di-notifica",
    component: _170f97fd,
    name: "impostazioni-di-notifica"
  }, {
    path: "/login",
    component: _be37e05c,
    name: "login"
  }, {
    path: "/luogo-di-lavoro",
    component: _1460e17d,
    name: "luogo-di-lavoro"
  }, {
    path: "/marchi",
    component: _c484515a,
    name: "marchi"
  }, {
    path: "/marketplace",
    component: _461c5498,
    name: "marketplace"
  }, {
    path: "/menu",
    component: _64c1c128,
    name: "menu"
  }, {
    path: "/notifiche",
    component: _3d457eb0,
    name: "notifiche"
  }, {
    path: "/pannello-amministrazione",
    component: _918feb82,
    name: "pannello-amministrazione"
  }, {
    path: "/password-reset",
    component: _bdcfacac,
    name: "password-reset"
  }, {
    path: "/profilo",
    component: _5ce7ea1c,
    name: "profilo"
  }, {
    path: "/punto-estetico",
    component: _46d9fd36,
    name: "punto-estetico"
  }, {
    path: "/ricarica-borsellino",
    component: _6727cc1f,
    name: "ricarica-borsellino"
  }, {
    path: "/scuole",
    component: _39d15470,
    name: "scuole"
  }, {
    path: "/test-estetista",
    component: _dc78ae44,
    name: "test-estetista"
  }, {
    path: "/aggiorna-profilo/biografia",
    component: _1d596472,
    name: "aggiorna-profilo-biografia"
  }, {
    path: "/aggiorna-profilo/diploma",
    component: _0e009ea4,
    name: "aggiorna-profilo-diploma"
  }, {
    path: "/aggiorna-profilo/immagine-copertina",
    component: _6ee93c71,
    name: "aggiorna-profilo-immagine-copertina"
  }, {
    path: "/aggiorna-profilo/immagine-profilo",
    component: _3599f94b,
    name: "aggiorna-profilo-immagine-profilo"
  }, {
    path: "/aggiorna-profilo/specializzazione",
    component: _3c09d5fa,
    name: "aggiorna-profilo-specializzazione"
  }, {
    path: "/gruppo/nuovo-post",
    component: _0d8df642,
    name: "gruppo-nuovo-post"
  }, {
    path: "/aggiorna-profilo/certificati/nuovo",
    component: _104bc18f,
    name: "aggiorna-profilo-certificati-nuovo"
  }, {
    path: "/aggiorna-profilo/esperienze-lavorative/nuovo",
    component: _96086398,
    name: "aggiorna-profilo-esperienze-lavorative-nuovo"
  }, {
    path: "/pannello-amministrazione/confestetica/gestione-utenti",
    component: _4125933b,
    name: "pannello-amministrazione-confestetica-gestione-utenti"
  }, {
    path: "/pannello-amministrazione/confestetica/richieste-iscrizione",
    component: _65d576b1,
    name: "pannello-amministrazione-confestetica-richieste-iscrizione"
  }, {
    path: "/pannello-amministrazione/confestetica/richieste-iscrizione-aziende",
    component: _04e025de,
    name: "pannello-amministrazione-confestetica-richieste-iscrizione-aziende"
  }, {
    path: "/pannello-amministrazione/confestetica/segnalazioni",
    component: _b5ecd572,
    name: "pannello-amministrazione-confestetica-segnalazioni"
  }, {
    path: "/pannello-amministrazione/gruppo/gestione-utenti",
    component: _8e2661ac,
    name: "pannello-amministrazione-gruppo-gestione-utenti"
  }, {
    path: "/pannello-amministrazione/gruppo/richieste-iscrizione",
    component: _0f9d4fbc,
    name: "pannello-amministrazione-gruppo-richieste-iscrizione"
  }, {
    path: "/",
    component: _ae2e6510,
    name: "index"
  }, {
    path: "/aggiorna-profilo/certificati/:id",
    component: _61bb59c5,
    name: "aggiorna-profilo-certificati-id"
  }, {
    path: "/aggiorna-profilo/esperienze-lavorative/:id",
    component: _6f60db6a,
    name: "aggiorna-profilo-esperienze-lavorative-id"
  }, {
    path: "/gruppo/modifica-post/:id?",
    component: _1101ecf0,
    name: "gruppo-modifica-post-id"
  }, {
    path: "/aziende-cosmetiche/:id",
    component: _7e4cde12,
    name: "aziende-cosmetiche-id"
  }, {
    path: "/centri-estetici/:id",
    component: _4af1df08,
    name: "centri-estetici-id"
  }, {
    path: "/chat/:id",
    component: _8676fc66,
    name: "chat-id"
  }, {
    path: "/estetiste/:id",
    component: _3f768e64,
    name: "estetiste-id"
  }, {
    path: "/gruppi-di-acquisto/:slug",
    component: _0e7f3d26,
    name: "gruppi-di-acquisto-slug"
  }, {
    path: "/marchi/:id",
    component: _6f35de84,
    name: "marchi-id"
  }, {
    path: "/posts/:id?",
    component: _ee8b3db8,
    name: "posts-id"
  }, {
    path: "/scuole/:id",
    component: _060ab75b,
    name: "scuole-id"
  }, {
    path: "/aziende-cosmetiche/:id?/immagine-copertina",
    component: _d383886a,
    name: "aziende-cosmetiche-id-immagine-copertina"
  }, {
    path: "/aziende-cosmetiche/:id?/immagine-profilo",
    component: _9e09f1b6,
    name: "aziende-cosmetiche-id-immagine-profilo"
  }, {
    path: "/centri-estetici/:id?/immagine-copertina",
    component: _5bdebc26,
    name: "centri-estetici-id-immagine-copertina"
  }, {
    path: "/centri-estetici/:id?/immagine-profilo",
    component: _05e5e240,
    name: "centri-estetici-id-immagine-profilo"
  }, {
    path: "/estetiste/:id/diploma",
    component: _32b6d718,
    name: "estetiste-id-diploma"
  }, {
    path: "/gruppi-di-acquisto/:slug?/acquisto-annullato",
    component: _7b6c4184,
    name: "gruppi-di-acquisto-slug-acquisto-annullato"
  }, {
    path: "/gruppi-di-acquisto/:slug?/acquisto-completato",
    component: _45d019b0,
    name: "gruppi-di-acquisto-slug-acquisto-completato"
  }, {
    path: "/gruppi-di-acquisto/:slug?/redirect",
    component: _4526b054,
    name: "gruppi-di-acquisto-slug-redirect"
  }, {
    path: "/marchi/:id/immagine-copertina",
    component: _260bfeb8,
    name: "marchi-id-immagine-copertina"
  }, {
    path: "/marchi/:id/immagine-profilo",
    component: _7d642e3e,
    name: "marchi-id-immagine-profilo"
  }, {
    path: "/scuole/:id/immagine-copertina",
    component: _8b11da32,
    name: "scuole-id-immagine-copertina"
  }, {
    path: "/scuole/:id/immagine-profilo",
    component: _7712157e,
    name: "scuole-id-immagine-profilo"
  }, {
    path: "/punto-estetico/*",
    component: _5d2ed6f4,
    name: "punto-estetico-all"
  }, {
    path: "/comunicazioni/*",
    component: _2945bfc7,
    name: "comunicazioni-all"
  }, {
    path: "/centri-estetici/*",
    component: _f78d9abe,
    name: "centri-estetici-all"
  }, {
    path: "/*",
    component: _29f74770,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
